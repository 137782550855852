<template>
  <div class="list-product-component">
    <KTCodePreview v-bind:title="'Danh sách linh kiện'">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown size="sm" id="dropdown-right" right variant="primary">
            <template slot="button-content">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>
              <span class="font-weight-bolder">Tạo mới</span>
            </template>
            <b-dropdown-item>
              <span
                style="color: #3f4254; width: 100%"
                @click="showModalAddCompany"
                v-show="checkPermission('PRODUCT_INSERT')"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                &nbsp; Tạo linh kiện
              </span>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link to="/product-component/import-product-component">
                <span style="color: #3f4254">
                  <i style="font-size: 1rem" class="la la-file-excel-o"></i>
                  &nbsp; Nhập từ file excel
                </span>
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <div class="col-md-12 mb-5">
          <b-form>
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <b-input
                    placeholder="Tìm kiếm theo tên"
                    v-model="searchName"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-4 mr-4"
                    @keyup="onSearch"
                    v-on:keyup.enter="onSearch"
                    size="sm"
                  ></b-input>
                  <b-input
                    placeholder="Tìm kiếm theo mã"
                    v-model="searchCode"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-4"
                    @keyup="onSearch"
                    v-on:keyup.enter="onSearch"
                    size="sm"
                  ></b-input>
                </div>
              </div>
            </div>
          </b-form>
        </div>
        <b-modal
          ref="add-company-modal"
          hide-footer
          title="Thêm mới linh kiện"
        >
          <v-form ref="form" lazy-validation>
            <b-col>
              <b-row>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Tên:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      v-model="name"
                      required
                      placeholder="Nhập tên"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-2-live-feedback"
                      >Yêu cầu nhập tên linh kiện</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
              </b-row>
              <b-row>
                <div class="col-md-6">
                  <b-form-group id="input-group-2" label-for="input-2">
                    <template>
                      <span>Mã:</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      v-model="code"
                      required
                      placeholder="Nhập mã linh kiện"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="input-group-1" label-for="input-price">
                    <template>
                      <span>Giá:</span>
                    </template>
                    <b-form-input
                      id="input-price"
                      size="sm"
                      v-model="price"
                      required
                      placeholder="Nhập giá"
                      class="text-right"
                      v-mask="mask"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-row>
              <div>
                <b-form-group
                  id="input-group-3"
                  label="Danh mục:"
                  label-for="input-3"
                >
                  <Autosuggest
                    :model="selectedCate"
                    :suggestions="filteredCateOptions"
                    :placeholder="'danh mục'"
                    :limit="200"
                    @select="onSelectedCate"
                    @change="onInputCateChange"
                  />
                </b-form-group>
              </div>
              <div>
                <b-form-group
                  id="input-group-3"
                  label="Nhóm sản phẩm:"
                  label-for="input-3"
                >
                  <Autosuggest
                    :model="selectedProduct"
                    :suggestions="filteredProductOptions"
                    placeholder="nhóm sản phẩm"
                    :limit="200"
                    @select="onSelectedProduct"
                    @change="onInputProductChange"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    v-show="checkPermission('PRODUCT_INSERT')"
                    @click="createProductComponent"
                    >Lưu</b-button
                  >
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hideModalAddCompany"
                    >Hủy</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </v-form>
        </b-modal>
        <b-modal
          ref="update-company-modal"
          hide-footer
          title="Cập nhật linh kiện"
        >
          <v-form ref="form" lazy-validation>
            <b-col>
              <b-row>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Tên:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      v-model="name"
                      required
                      placeholder="Nhập tên"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-2-live-feedback"
                      >Yêu cầu nhập tên linh kiện</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
              </b-row>
              <b-row>
                <div class="col-md-6">
                  <b-form-group id="input-group-2" label-for="input-2">
                    <template>
                      <span>Mã:</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      v-model="code"
                      required
                      placeholder="Nhập mã linh kiện"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="input-group-1" label-for="input-price">
                    <template>
                      <span>Giá:</span>
                    </template>
                    <b-form-input
                      id="input-price"
                      size="sm"
                      v-model="price"
                      required
                      placeholder="Nhập giá"
                      class="text-right"
                      v-mask="mask"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-row>
              <div>
                <b-form-group
                  id="input-group-3"
                  label="Danh mục:"
                  label-for="input-3"
                >
                  <Autosuggest
                    :model="selectedCate"
                    :suggestions="filteredCateOptions"
                    placeholder="danh mục"
                    :limit="200"
                    @select="onSelectedCate"
                    @change="onInputCateChange"
                  />
                </b-form-group>
              </div>
              <div>
                <b-form-group
                  id="input-group-3"
                  label="Nhóm sản phẩm:"
                  label-for="input-3"
                >
                    <Autosuggest
                    :model="selectedProduct"
                    :suggestions="filteredProductOptions"
                    placeholder="nhóm sản phẩm"
                    :limit="200"
                    @select="onSelectedProduct"
                    @change="onInputProductChange"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    v-show="checkPermission('PRODUCT_INSERT')"
                    @click="updateProductComponent"
                    >Lưu</b-button
                  >
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hideModalUpdateCompany"
                    >Hủy</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </v-form>
        </b-modal>
        <b-table
          :items="listItem"
          :fields="fields"
          class="table-bordered table-hover col-md-12"
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="4"></vcl-table>
          </template>
          <template v-slot:cell(stt)="row">
            <div class="d-flex justify-content-center w-6">
              <span v-text="row.item.stt"></span>
            </div>
          </template>
          <template v-slot:cell(price)="row">
            <div style="text-align: end">
              <span v-text="convertPrice(row.item.price)"></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 10px" class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-show="checkPermission('PRODUCT_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số linh kiện:
              {{ totalRow }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-show="numberOfPage >= 2"
              class="customPagination"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-1 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1"
              last-class="page-item-last btn btn-icon btn-sm my-1"
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            ></b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '@/utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import { required } from 'vuelidate/lib/validators';
import {
  removeAccents,
  unMaskPrice,
  currencyMask
} from '@/utils/common';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { getToastInstance } from '@/utils/toastHelper';
import { convertPrice } from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      searchName: '',
      searchCode: '',
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%'
          },
          tdClass: 'sttCateClass',
          thClass: 'sttCateClass'
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' }
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' }
        },
        {
          key: 'productComponentCategoryName',
          label: 'Danh mục',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' }
        },
        {
          key: 'productComponentGroupName',
          label: 'Nhóm sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' }
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' }
        },
        { key: 'actions', label: '' }
      ],
      listItem: [],
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
      count: 1,
      name: '',
      selectedCate: '',
      selectedCateId: null,
      valid: true,
      productComponentId: 0,
      totalRow: 0,
      onLoading: false,
      submitted: false,
      isNew: true,
      productOptions: [
        {
          data: []
        }
      ],
      cateOptions: [
        {
          data: []
        }
      ],
      filteredProductOptions: [],
      filteredCateOptions: [],
      selectedProduct: '',
      selectedProductId: null,
      isSearching: false,
      code: '',
      price: 0,
      mask: currencyMask,
      componentId: null
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest
  },
  validations: {
    name: {
      required
    }
  },
  created() {
    this.fetchData();
    this.getListCate();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Linh kiện', route: '/product-component' },
      { title: 'Danh sách linh kiện' }
    ]);
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    onSearch() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-product-component'
      });
      this.fetchData();
    },
    fetchData: async function() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let param = {
        page: this.page,
        limit: 10,
        name: this.searchName,
        code: this.searchCode
      };
      let paramQuery = {
        params: param
      };
      this.onLoading = true;
      ApiService.setHeader();
      ApiService.query('productComponent/', paramQuery)
        .then(({ data }) => {
          if (this.page === 1) {
            this.count = 1;
          } else {
            this.count = 1 + (this.page - 1) * 10;
          }
          this.count = 1;
          this.totalRow = data.data.total_row;
          this.numberOfPage = data.data.total_page;
          this.listItem = [];
          data.data.data.forEach(element => {
            let item = {
              id: element.id,
              stt: this.count,
              name: element.name,
              code: element.code,
              price: element.price,
              productComponentGroupName: element.productComponentGroupName,
              productComponentCategoryName: element.productComponentCategoryName
            };
            this.listItem.push(item);
            this.count++;
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function() {
      let count = 0;
      if (localData.checkPermission('COMPANY_VIEW')) {
        count++;
      }
      if (localData.checkPermission('COMPANY_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    deleteItem: async function(item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.delete(`productComponent/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFailure(data.message);
        }
      });
    },
    showDeleteAlert: function(item) {
      Swal.fire({
        title: 'Xóa linh kiện!',
        text: 'Bạn có chắc muốn xóa linh kiện này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    editItem: function(item) {
      this.productComponentId = item.id;
      this.getProductComponentById();
      this.showModalUpdateCompany();
    },
    getProductComponentById: function() {
      ApiService.setHeader();
      ApiService.get(`productComponent/${this.productComponentId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.name = data.data.name;
            this.code = data.data.code;
            this.selectedCateId = data.data.cateId;
            this.selectedProductId = data.data.productComponentGroupId;
            this.selectedCate = data.data.productComponentCategoryName;
            this.selectedProduct = data.data.productComponentGroupName;
            this.componentId = data.data.productComponentId;
          } else {
            this.makeToastFailure('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    showModalAddCompany() {
      this.isNew = true;
      this.selectedCate = '';
      this.selectedProduct = '';
      this.selectedCateId = null;
      this.selectedProductId = null;
      this.name = '';
      this.code = '';
      this.$refs['add-company-modal'].show();
    },
    hideModalAddCompany() {
      this.$refs['add-company-modal'].hide();
    },
    showModalUpdateCompany() {
      this.isNew = true;
      this.$refs['update-company-modal'].show();
    },
    hideModalUpdateCompany() {
      this.$refs['update-company-modal'].hide();
    },
    createProductComponent: function() {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const name = this.name;
      const code = this.code;
      let data = {
        code: code,
        name: name,
        price: unMaskPrice(this.price),
        cateId: this.selectedCateId,
        productComponentGroupId: this.selectedProductId
      };
      ApiService.setHeader();
      ApiService.post('productComponent/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalAddCompany();
          } else {
            this.makeToastFailure(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    updateProductComponent: async function() {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const name = this.name;
      const code = this.code;
      let data = {
        id: this.productComponentId,
        name: name,
        code: code,
        price: unMaskPrice(this.price),
        cateId: this.selectedCateId,
        productComponentGroupId: this.selectedProductId,
        productComponentId: this.componentId
      };
      ApiService.setHeader();
      ApiService.put('productComponent/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalUpdateCompany();
          } else {
            this.makeToastFailure(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCate = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCate = text;

      const filteredData = this.cateOptions[0].data
        .filter(item => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [...filteredData];
    },
    onSelectedProduct(option) {
      this.selectedProductId = option.item.id;
      this.selectedProduct = option.item.name;
    },
    onInputProductChange(text) {
      this.selectedProduct = text;

      this.filteredProductOptions = [];
      this.debounceInputProduct();
    },
    searchProduct: function(textSearch) {
      ApiService.setHeader();
      ApiService.query(`productComponentGroup/search?name=${textSearch}`).then(
        response => {
          this.productOptions[0].data = [];
          const products = response.data.data;
          products.map(element => {
            this.productOptions[0].data.push(element);
          });
          this.filteredProductOptions = [...this.productOptions[0].data];
          this.isSearching = false;
          if (textSearch !== this.selectedProduct) {
            let nextTextSearch = this.selectedProduct;
            this.searchProduct(nextTextSearch);
          }
        }
      );
    },
    debounceInputProduct: decounce(function() {
      this.fetchProduct();
    }, TIME_TRIGGER),
    fetchProduct() {
      if (!this.isSearching) {
        let textSearch = this.selectedProduct;
        this.searchProduct(textSearch);
      }
    },
    getListCate: function() {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: ''
      };
      let paramQuery = {
        params: param
      };
      ApiService.setHeader();
      ApiService.query('productComponentCategory', paramQuery).then(
        ({ data }) => {
          this.cateOptions[0].data = [];
          this.numberOfPage = data.data.total_page;
          data.data.list_caterogy.forEach(element => {
            let name = '';
            if (element.level === 1) {
              name = '-' + element.name;
            } else if (element.level === 2) {
              name = '--' + element.name;
            } else if (element.level === 3) {
              name = '---' + element.name;
            } else if (element.level === 4) {
              name = '----' + element.name;
            } else if (element.level === 5) {
              name = '-----' + element.name;
            } else if (element.level === 6) {
              name = '------' + element.name;
            } else {
              name = element.name;
            }
            let item = {
              id: element.id,
              name: name
            };
            this.cateOptions[0].data.push(item);
          });

          this.filteredCateOptions = [...this.cateOptions[0].data];
        }
      );
    }
  }
};
</script>

<style lang="scss">
.list-product-component {
  .icon {
    padding-top: 7px;
  }
  .ml-10 {
    margin-left: 5px;
  }
  .ml-20 {
    margin-left: 10px;
  }
  .ml-30 {
    margin-left: 15px;
  }
  .ml-40 {
    margin-left: 20px;
  }
  .ml-50 {
    margin-left: 25px;
  }

  .sttCateClass {
    width: 6%;
  }
  .nameCateClass {
    width: 45%;
  }
  .codeCateClass {
    width: 45%;
  }
  .icon:hover {
    background-color: #90c6fc;
  }

  .row-fluid {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .centering {
    float: none;
    margin: 0 auto;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
}
</style>